import { create } from "zustand";
import jwtDecode from 'jwt-decode';

import { callApi } from 'utils';

// init auth token for revisited user
function decodeToken() {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  }
  try {
    const decoded = jwtDecode(token);
    //console.log('jwt:', decoded);
    if (!decoded.exp || decoded.exp > Date.now() / 1000) {
      return decoded;
    }
  } catch (ex) {
    console.log(ex);
  }
  localStorage.removeItem('token');
  return null;
}

function getJwtData() {
  const data = decodeToken();
  return { session: data, loggedIn: !!data };
}

let loggedInFunc = [];

const useSession = create((set, get) => ({
  ...getJwtData(),
  tz: 'Asia/Shanghai',
  login: async (user) => {
    const { token } = await callApi('/users/login', 'POST', user, false);
    localStorage.setItem("token", token);
    set({ ...getJwtData() });
    //get().updateProfile();
    if (loggedInFunc.length > 0) {
      loggedInFunc.forEach(func => setTimeout(func, 0));
      loggedInFunc = [];
    }
  },
  logout: () => {
    localStorage.removeItem("token");
    set({ session: null, loggedIn: false });
  },
  updateProfile: () => callApi('/users/profile').then((resp) => {
    const old = get();
    const same = Object.keys(resp).every((key) => resp[key] === old?.[key]);
    if (!same) {
      let tz = resp.tz;
      delete resp.tz;
      set((state) => ({ session: { ...state?.session, ...resp }, tz }))
    }
  }).catch(() =>
    set({ session: null, loggedIn: false })
  ),
}));

export function hookLoggedIn(func) {
  if (useSession.getState().loggedIn) {
    func();
  } else {
    loggedInFunc.push(func);
  }
}

// update profile if logged in
hookLoggedIn(() => useSession.getState().updateProfile());

export default useSession;
