// ref: PostbackType... in types/const.go
export const postbackTypes = {'imp': 'Imp Trackers', 'clk': 'Click Trackers', 'dst': 'Landing Url'};

// ref: JobAttr... in types/const.go
export const jobAttrExcludeRegion = 4;
export const jobAttrs = [
  {val: 4, display: 'Exclude Regions'},
  {val: 16, display: 'Direct Landing'},
];

// some images data url
export const miaozhenIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADh0lEQVR4Ae3WY3RuRxgG0Lm2bdu2rVrXtm3btm3bSvIltW3b/d9d28a71j7GczgT/vL6v/6vl0P6UlQikJUGJOCXHieQkuak4GfvOJGTJKInl0lFehLxSwLkIpYyvyTACp6jFMeIJhOjyPsLA6TnKnV/SYCNvMBEHuYkaWlGBjJTiXQE4lOGsiSkFLWpTg7OMI5aJCVQiOtIR0UKUYpMwWAtm3mabWwhD+eowQIeZxNJqcc9PEgVphPhea7jIo/wNNeSihO8xmB2cx+PMCcY7GEUj9OHzeQjihrkpjxXqMwCprOfXsSnLqcowhkaMofR1COW+axgI3MYwJ5gcJFbmER9tpGPKKqRkFrcwa3spwWjGUVgMvNIzylKMISx9ORFIl8LMYD6HAoGcdQmUJad5OMqlenCRZ6iNYepQW9Gk54rNCY9ZyjCEMYwhU00Jw8LGUQdzn0RoCaBcuwjP1epxSoac4T2HKUWQxlAcy6RnqxcoDCDGcN0+hMIzGEIdYgKBrFUI1CewxTiKvVZQ0kO0pHjtGMjXVnGKAI5uEpRBjOaPswgEJjLcGoTFwxiqESgMhcpyRVqsJTtPMxtLOZ2YmnJg2xjPK2JpgSDGUcl7mIBrVjCSGpzRzDoRzYSs4h1ZGQAOSnFKKZQnGIsoz2p6c4GVtGIQWShHk2IT2uW04QbaUg+Bn79L1aVeyjCH9XufMfXVzQhirRk4joSUZmSFKINaWlKGgLp6E0dCtOKltxMe24kIXloTxq+N1lh4ihPMaLJxSJGcIiVlOAstUlCP57iCD15jou8zTEeohpb2UXaHwoQj5ksIzPnqc1GpnKSLCzlIx5gFEcZym66sZ3y3EFJtjORU+TgR59Pae6iKuvpxQ5af+0AFYhwM3V4kjiO04a1FCBCUZYxkeMU+dEAJGQvvT+3k8NkZw+jSMthClGVe2lBHhqyifxEKMlSurOcBcTjBwLAaibRlNfZQaAFlyjEQQpTlUukJ1CfreQjhjIspRNViCMvP3jy/ERoRyneZTqBskQozX6KkY9zFCLQgB3kJYayLKUzebiD8j8UoAYR1pCS3DxDW9JxmNWk5ShnaM1izjCFG9hJXq5SlsUMYD17SPVDASrQjtQEinAnpchIOzISqM0wypKdHtxMQZqQghtJT20q0YacBH7WH+sG9pCEP7WbHkjEFvoT/ooAyehHvj/65P/Xx0CqM2ZNTQA5AAAAAElFTkSuQmCC";
export const baiduIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAPFBMVEVHcEz////////9/f/////p6/2srvK6vPXZ2vrIyvcACt8LG+A9ReUbJuFpb+ooMeFWXOeLj+2bn+98gexUG/hRAAAABHRSTlMAEaz5aKQWgAAAAU9JREFUeAGF092SgyAMBWCr/J54DOr7v+tCRqhdZ7fchJJvMk2QaXrNi/tjLfOr5t2/6zXNY+9DtJhCfoN5GvUTRNYaI4htgGUa21VVJTgnNeJdY4CEmuDmklh8gtgSuvb4BNkSpccncHL9B1j8BJHYXYYI648DRHFJsS0dZKgi3Odg9Vg6KFqXuPsK1my6ABuAt2nGbGBrZxIvgA6WAhGJHTAY6LwNk0arONiLNuDB1oY7LW8JOzpHm/7cQrslhZhY+9F9UNas3YQ1+JxkgA6gXJff4AQBqYBsgEyfoIgcLSYkRx5uJ/IdnNJ6TsmAGLAmRxdoYyDkDrgPYB+JfVJ3oPoGQRTOlasCsRvgG2TpBROixQpYBrDrki157yvNNfiTKuEGYhUUAUACIkLlerW5WMhEy5f9ODZtAmd/OP3ppZx9f01130c9f3+8357/D50CHYMuiWz1AAAAAElFTkSuQmCC";
export const googleIcon = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAADmUlEQVR4Ab1XA5AjQRQ9G6UrnV0627Zt27Zt27Zt27Z9FyeXiZN3v1NzW9vTmd3J6lW9cKbf6690EmlFIBDIQGzr9/vXE+8RjUSPTIP82Xq6pg27VvPCGoTz08KbiE5oBF3rIG6ge/PGWBhAGlpkAdEHzRCMeInzyEjqcHedj258gbgBM/KU1syjVbwo3aBHCPh+foe0YyMsw/vC0KI2dDVLQ1ejFAzNasI8tDekLWvh+/5VzYQOQGENOxfFfb9+wDplNHTVS0JXrUTUpGusk0aye0Ka4CPBi6cNFXbn2RPQ16vIi2igvn4luC6dVUuHWBOs4KCAtGtzOKICbXOnqKVjbqhW8yl2rrqwqUcb2Nctg/P4QThPHKLXy2Hq2ZYXXzgTCARUu4NLBetzZbHp61YQhI3tG8N9/zbU4L53C4bW9fF3+XwtnbH+f79nVA4Z++IBgri5fxcEbDZEh4Bk19qaEkUhPQt/W24Bxwd4z6SEdVSOCHHWcn6LCXEN0m7Fwr+ec/ZpJrznkwcpLcsEfZ3icJ4+hvgAaa9hBu5x+X9Qk4lH0H0wJ+D1xpeB28yAEZHgvZqFM+B71gZRoeoMu2ZeeuEVBhMz4OEMXEjJGfC/nxBnBnbc8CgNuBPUwKYr7pAGDFGm4GnrODOw97YQAZ1YhA9rcQa+Xy0Ij9+LcPFR5xcMXHnlFYtQbMPZEeLHTuRE+d2NcfjDeYSL3bc8goFfloDYhuwMxw+iT3CcT42Zh4uh+O5mQdY83BUGpxla8dcZQPPFEifeeY0j1CBqGTxskhPu20W3pjJhju3PjIDFHf0o9viAUbucwu73iPmXAKT7/2O0AZHw065Dxf3tBBP1j/bC1R/3oYa35s/odmQ3CVo5cRYNh1sI/9rIP8d5/X6+0k59vioLi2x+ciAWPNyEvW9PYd+701jxdAe6nx+HErubB78vtXkUqsz5FmHg2mufUtxDmrmVB5J5UGDb6yOCuFaW2NEZFRfeFXpfNjBL7Rj+DAqc+XKd0tE+bAOl9rTEuqdHQok/BpBK7VyYRz69cvgl6THu5iKU3NNck3ivixPxyvQhlPhvALmi+0NSmDfBF+eWV4fQ//JU1DnSA6X3tmQ7pTbthh4XJgRr4bXpI2QI4rTBQurKYiSeIo7Awi7sXIOJ1Oz0SvTGQthDnKWW83CisZ4ohSEsEdfKrRY3YAdIYis2v4m3iTqiW6ZO/mwNG6/yhNOEf6HhfzYhUKeuAAAAAElFTkSuQmCC";
