import React, { useState, useEffect } from 'react';
import {
  Box,
} from '@mui/material';

import { callApi } from 'utils';
import { Popup } from 'components';

function formatPercent(data) {
  const sum = Object.values(data).reduce((acc, val) => acc + val, 0);
  return Object.keys(data).sort().map(key => {
    let pct = (100*data[key]/sum).toFixed(2);
    return `${key}: ${pct}%/${data[key]}`;
  }).join(', ');
}

const Label = ({name}) => (
  <span style={{color: '#546e7a', textAlign: 'right', marginRight: '8px'}}>{name}:</span>
);

const DeviceInspect = ({fcid, onClose}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fcid && callApi(`/systems/arcdev/${fcid}`).then(setData).catch(console.error);
  }, [fcid]);

  if (!fcid)
    return null;

  let pv_stats, osv_stats;
  if (data?.pv_count) {
    delete data.pv_count['0'];
    pv_stats = formatPercent(data.pv_count);
  }
  if (data?.osv_count) {
    osv_stats = formatPercent(data.osv_count);
  }

  return (
    <Popup
      open={Boolean(fcid)}
      onClose={onClose}
      title={`Inspect device state for fcid ${fcid}`}
      styles={{width: '400px'}}
    >
      {data && (
      <Box>
        <p><Label name="Device Type"/>{data.device_type}</p>
        <p><Label name="FcID"/>{data.id}</p>
        <p><Label name="Last DevID"/>{data.last_devid || 'N/A'}</p>
        <p><Label name="Pv Stats"/>{pv_stats || 'N/A'}</p>
        <p><Label name="Osv Stats"/>{osv_stats || 'N/A'}</p>
        <p><Label name="Data"/>{data.job_data}</p>
        <p><Label name="Last Used"/>{data.last_used}</p>
      </Box>
      )}
    </Popup>
  );
};

export default DeviceInspect;
