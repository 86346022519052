import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
} from '@mui/material';
import { useImmer } from "use-immer";

import { useMisc } from 'store';
import { Page, FormLabel } from 'components';
import { callApi } from 'utils';

const BatchAdd = () => {
  const navigate = useNavigate();
  const misc = useMisc((state) => state.data);

  const [formState, setFormState] = useImmer({
    values: {
      org: 1,
      ant_cfg: 'click-randpos.yml',
      extra: '',
      content: '',
    },
    errors: null,
  });
  const [saving, setSaving] = useState(false);

  const {values: vals, errors: errs} = formState;

  const handleChange = useCallback((ev) => {
    const t = ev.target;
    setFormState(draft => {
      draft.values[t.name] = t.type === 'checkbox' ? t.checked : t.value;
    });
  }, [setFormState]);

  const disableSubmit = saving;
  Boolean(errs) && console.log(errs);

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (disableSubmit) {
      return false;
    }
    setSaving(true);

    const data = {
      ...vals,
      // not saving \n for psql display and easy backup of job table
      extra: vals.extra.replaceAll('\n', '@@'),
    };
    callApi('/jobs/batch', 'POST', data)
      .then((res) => {
        if (res.errors) {
          setFormState(draft => {
            draft.errors = res.errors;
          });
          setSaving(false);
        } else {
          navigate('/jobs');
        }
      }).catch(() => setSaving(false));
  };

  const hasError = (field) => Boolean(errs?.[field]);

  return (
    <Page styles={{padding: 3}} title="Batch Add">
      <Card>
        <form>
          <CardHeader title="Batch Add" />
          <Divider />
          <CardContent>
            <Stack direction="row" spacing={2}>
              <Stack direction="row" spacing={1}>
                <FormLabel gutterBottom variant="subtitle1">Organization:</FormLabel>
                <Select
                  name="org"
                  value={vals.org}
                  size="small"
                  onChange={handleChange}
                >
                {Object.entries(misc['org']).map(([val, display]) => (
                  <MenuItem value={val|0} key={val}>{display}</MenuItem>
                ))}
                </Select>
              </Stack>
              <Stack direction="row" spacing={1}>
                <FormLabel gutterBottom variant="subtitle1">Page Actions:</FormLabel>
                <Select
                  name="ant_cfg"
                  value={vals.ant_cfg}
                  size="small"
                  onChange={handleChange}
                  displayEmpty
                >
                {Object.entries(misc['ant_cfg']).map(([val, display]) => (
                  <MenuItem value={val} key={val}>{display}</MenuItem>
                ))}
                </Select>
              </Stack>
            </Stack>
            <div style={{margin: '8px'}}>
              <FormControl sx={{width: '100%'}}>
                <OutlinedInput
                  sx={{padding: 0, width: '100%'}}
                  name="content"
                  value={vals.content}
                  inputProps={{ style: {padding: '8.5px 14px'} }}
                  error={hasError("content")}
                  size="small"
                  rows={10}
                  onChange={handleChange}
                  multiline
                />
                <FormHelperText sx={{marginLeft: 0}}>
                  {errs?.content ? (
                    <span style={{color: "red"}}>{errs.content}.</span>
                  ) : (
                    <span>Headers must be put as first line, tab separated.</span>
                  )}
                </FormHelperText>
              </FormControl>
            </div>
            <Stack direction="row" spacing={1}>
              <FormLabel gutterBottom variant="subtitle1">Extra Info:</FormLabel>
              <OutlinedInput
                sx={{padding: 0, width: 800}}
                name="extra"
                value={vals.extra}
                error={hasError("extra")}
                size="small"
                inputProps={{ style: {padding: '8.5px 14px'} }}
                onChange={handleChange}
                multiline
              />
            </Stack>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleSubmit}
              disabled={disableSubmit}
            >
              {saving ? "Saving..." : "Save"}
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => navigate('/jobs') }
            >
              Cancel
            </Button>
          </CardActions>
        </form>
      </Card>
    </Page>
  );
};

export default BatchAdd;
