import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { useVisibilityChange, useDebounce } from "@uidotdev/usehooks";
import {
  Button,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Input,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AdsClick as TrackIcon,
  AvTimer as HourlyReportIcon,
  BarChart as ReportIcon,
  CalendarViewMonthOutlined as CalendarIcon,
  ContentCopy as CopyIcon,
  EditOutlined as EditIcon,
  MoreVert as MoreVertIcon,
  Pause as PauseIcon,
  PhoneAndroid as DeviceIcon,
  PlayArrow as PlayIcon,
  ShareLocation as LocationReportIcon,
  Search as SearchIcon,
} from '@mui/icons-material';

import { useSession, useMisc } from 'store';
import { Page } from 'components';
import { Overview, JobState, LinkView, JobReport, DeviceCheck, TrackList } from './components';
import { callApi } from 'utils';
import { miaozhenIcon, baiduIcon, googleIcon } from 'consts';

const TrackData = ({percent, icon}) => (
  <Typography sx={{display: 'flex', alignItems: 'center', fontSize: 'inherit'}}>
    <img style={{height: '18px', width: '18px', marginRight: '3px'}} src={icon} alt="agent" />
    {percent}%
  </Typography>
);

const columnHelper = {
  data: {},
  ymd: '',
  setData(misc, activeJobs, report) {
    this.data = {misc, activeJobs, report};
    this.ymd = dayjs().tz().format('YYYYMMDD');
  },
  dayPlan(job) {
    if (this.data.activeJobs?.includes(job)) {
      const imps = isNaN(job.daily_imps[this.ymd]) ? job.daily_imps.default : job.daily_imps[this.ymd];
      if (imps > 0) {
        return (
          <Typography component="span" sx={{fontSize: 'inherit'}}>
            {imps}
            {job.overrun > 0 && (<span style={{marginLeft:'3px',color:'rgb(159,159,159)'}}>+{job.overrun}%</span>)}
          </Typography>
        );
      }
    }
    return '--';
  },
  dayparts(job) {
    return (job.dayparts[this.ymd] || job.dayparts.default || ["00:00", "23:59"]).join('~');
  },
  miscData(cat, val) {
    return this.data.misc?.[cat]?.[val];
  },
  reportData(job, field) {
    if (!this.data.activeJobs?.includes(job))
      return '--';

    const d = this.data.report?.[job.id];
    if (!d) return '--';
    if (field === 'ctr')
      return (100*d.clicks/d.imps).toFixed(1);
    else if (field === 'landing')
      return d.clicks > 0 ? (100*d.landing/d.clicks).toFixed(1) + '%' : '--';
    else if (field === 'progress') {
      const ps = d.progress;
      if (!ps?.[1]) return '--';
      const color = ps[1]-ps[0] >= 0.15 ? 'red' : 'inherit';
      return (<span style={{color}}>{ps.map(p => (100*p).toFixed(1) + '%').join(' / ')}</span>);
    } else if (field === 'track') {
      const t = [[d.c_mz, miaozhenIcon], [d.c_ga, googleIcon], [d.c_bd, baiduIcon]].find(a => a[0] > 0);
      if (!t)
        return '--';
      const p = (100*t[0]/d.landing).toFixed(1);
      if (p === '0.0')
        return '--';
      return (<TrackData percent={p} icon={t[1]} />);
    }
    return d[field] || 0;
  },
};

const columns = {
  id: {
    name: 'ID',
    value: row => row.id,
  },
  name: {
    name: 'Name',
    value: row => row.extra ? (<Tooltip title={row.extra.replaceAll('@@',' / ')}><span>{row.name}</span></Tooltip>) : row.name,
  },
  type: {
    name: 'Type',
    value: row => row.ctr === 100 ? 'Click' : (row.ctr ? 'Mixed' : 'Imp'),
  },
  schedule: {
    name: 'Start/End Date',
    value: row => row.start_date + ' ~ ' + row.end_date,
  },
  dayparts: {
    name: 'Daypart',
    value: row => columnHelper.dayparts(row),
  },
  url: {
    name: 'Landing Url',
    value: row => (<LinkView destUrl={row.dest_url} trackers={row.trackers} />),
  },
  traffic: {
    name: 'Traffic',
    value: row => columnHelper.miscData('traffic_curve', row.traffic_curve),
  },
  plan: {
    name: 'Plan',
    value: row => columnHelper.dayPlan(row),
  },
  imps: {
    name: 'Imps',
    value: row => columnHelper.reportData(row, 'imps'),
  },
  clicks: {
    name: 'Clicks',
    value: row => columnHelper.reportData(row, 'clicks'),
  },
  ctr: {
    name: 'CTR',
    value: row => columnHelper.reportData(row, 'ctr'),
  },
  landing: {
    name: 'Landing',
    value: row => columnHelper.reportData(row, 'landing'),
  },
  progress: {
    name: 'Progress',
    value: row => columnHelper.reportData(row, 'progress'),
  },
  track: {
    name: 'Track',
    value: row => columnHelper.reportData(row, 'track'),
  },
};

function reload(flt, q, [page, limit], cb) {
  callApi(`/jobs?status=${flt.status}&org=${flt.org}&q=${encodeURIComponent(q)}&page=${page}&limit=${limit}&sort=-id`)
    .then(cb).catch(console.error);
}

function loopGetRtReport(active, cb, delay) {
  if (!active)
    return;

  const getReport = () => callApi(`/reports/realtime`).then(rows =>
    cb(Object.fromEntries(rows.map(row => [row.ad, row])))
  ).catch(console.error);

  getReport();
  const itvl = setInterval(() => getReport(), delay);
  return () => clearInterval(itvl);
}

function download(url, basename) {
  // https://stackoverflow.com/questions/695151/data-protocol-url-size-limitations
  callApi(url)
    .then(resp => fetch(`data:application/octet-stream;base64,${resp.b64}`))
    .then(resp => resp.blob())
    .then(blob => {
      const fn = basename + '-' + dayjs().tz().format('MMDD_HHmmss') + '.xlsx';
      // create blob link to download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.setAttribute('download', fn);
      document.body.appendChild(a);
      a.click();
      setTimeout(() => { a.parentNode.removeChild(a); window.URL.revokeObjectURL(url); }, 200);
    })
    .catch(console.error);
}

const JobList = () => {
  const documentVisible = useVisibilityChange();
  const session = useSession((state) => state.session);
  const misc = useMisc((state) => state.data);
  const [pagination, setPagination] = useState([0, 50]);
  const [list, setList] = useState({rows:[],total:0});
  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState({status: 'active', org: ''});
  const [search, setSearch] = useState('');
  const [reportDays, setReportDays] = useState(1);
  const [pageAction, setPageAction] = useState('');
  const [modal, setModal] = useState(null);
  const [report, setReport] = useState({}); // realtime report
  const [moreOpsEl, setMoreOpsEl] = useState(null);
  const q = useDebounce(search, 1500);

  const isAdmin = session.role === 'admin';
  const isWarren = session.uid === 2;

  useEffect(() => {
    reload(filter, q, pagination, setList);
    setSelected([]);
  }, [filter, q, pagination]);

  const handleSelect = useCallback((ev) => setSelected(prev => {
    const t = ev.target, v = Number(t.value);
    const next = [...prev];
    const i = next.indexOf(v);
    if (t.checked) {
      i === -1 && next.push(v);
    } else {
      i >= 0 && next.splice(i, 1);
    }
    return next;
  }), []);

  const exportSelectedJobs = useCallback(() => {
    if (!selected.length) return;
    const url = `/jobs/export?ids=${selected.join(',')}`;
    download(url, 'jobs');
    setSelected([]);
  }, [selected]);

  const handleFilter = useCallback((ev) => {
    const t = ev.target;
    if (t.name === 'search') {
      // set search separately since we need to use debounce on search input
      setSearch(t.value);
    } else {
      setFilter(prev => ({...prev, [t.name]: t.value}));
    }
    setPagination(prev => [0, prev[1]]);
  }, []);
  const handleStatus = useCallback((ev, val) => {
    // ToggleButtonGroup do not have a name property
    ev.target.name = 'status';
    ev.target.value = val;
    handleFilter(ev);
  }, [handleFilter]);
  const handlePageChange = useCallback((_, page) => setPagination(prev => [page, prev[1]]), []);
  const handleRowsPerPageChange = useCallback((ev) => setPagination(prev => [prev[0], ev.target.value]), []);
  const reloadJobs = useCallback(() => reload(filter, q, pagination, setList), [filter, q, pagination]);

  const handleOpenMoreOps = useCallback((ev) => setMoreOpsEl(ev.currentTarget), []);
  const handleCloseMoreOps = useCallback(() => setMoreOpsEl(null), []);
  const moreopsElId = moreOpsEl?.id || '';
  const moreopsJob = moreopsElId.startsWith('moreops') ? list.rows.find(row => row.id === Number(moreopsElId.substring(8))) : null;

  const clickMenu = useCallback((op) => {
    const jobId = moreopsJob.id;
    if (op === 'hourly-report' || op === 'region-report') {
      const url = `/reports/export/${jobId}?dim=${op}`;
      download(url, op + '-' + moreopsJob.id);
    } else {
      setModal([op, moreopsJob]);
    }
  }, [moreopsJob]);

  const openOverview = useCallback(() => setModal(['overview']), []);
  const showJobState = useCallback((id) => setModal(['state', id]), []);
  const confirmSuspend = useCallback((suspend, id) => setModal(['suspend', [suspend, id]]), []);
  const selectExportDays = useCallback(() => setModal(['expall']), []);
  const batchSetPageAction = useCallback(() => setModal(['pageact']), []);
  const closeModal = useCallback(() => setModal(null), []);
  const handleChangeReportDays = useCallback((ev) => setReportDays(ev.target.value), []);
  const handleSetPageact = useCallback((ev) => setPageAction(ev.target.value), []);

  let modalType, modalData, suspendJobs, dialogContent;
  if (modal) {
    const [type, args] = modal;
    modalType = type;
    const job = typeof args === 'number' ? list.rows.find(row => row.id === args) : args;
    if (type === 'state' || type === 'tracks') {
      modalData = { job, stats: report[job?.id] };
    } else if (type === 'report' || type === 'device') {
      modalData = job;
    } else if (type === 'suspend') {
      const suspend = args[0];
      const jobIds = args[1] ? [args[1]] : selected;
      suspendJobs = list.rows.filter(row => row.suspend !== suspend && jobIds.includes(row.id));
      const l = suspendJobs.length;
      if (l === 0) {
        setModal(null);
      } else {
        let txt = (suspend ? 'Suspend ' : 'Continue ') +
          (l === 1 ? `job ${suspendJobs[0].id}: ${suspendJobs[0].name}?` : `selected ${l} jobs?`);
        dialogContent = (<DialogContentText>{txt}</DialogContentText>);
      }
    } else if (type === 'expall') {
      dialogContent = (
        <Box>
          <span style={{marginRight: '5px'}}>Export last</span>
          <Select value={reportDays} size="small" onChange={handleChangeReportDays}>
            {[1,2,3,4,5,6,7].map((n) => (<MenuItem value={n} key={n}>{n} days</MenuItem>))}
          </Select>
        </Box>
      );
    } else if (type === 'pageact') {
      dialogContent = (
        <Box>
          <span style={{marginRight: '5px'}}>Page Action: </span>
          <Input
            sx={{ml: '8px', p: 0, width: 150}}
            value={pageAction}
            size="small"
            onChange={handleSetPageact}
          />
        </Box>
      );
    }
  }

  const confirmDialog = () => {
    if (modalType === 'expall') {
      const url = `/reports/export/batch?days=${reportDays}`;
      download(url, `last-${reportDays}-days`);
      setModal(null);
    } else {
      let ids, data;
      if (modalType === 'suspend') {
        ids = suspendJobs.map(job => job.id);
        data = {suspend: !suspendJobs[0].suspend};
      } else if (modalType === 'pageact') {
        ids = selected;
        data = {ant_cfg: pageAction};
      } else {
        return;
      }
      if (!ids?.length) return;
      const url = `/jobs/batch?ids=${ids.join(',')}`;
      callApi(url, 'PUT', data).then(() => {
        setModal(null);
        setPageAction('');
        setSelected([]);
        reloadJobs();
      }).catch(console.error);
    }
  };

  const today = dayjs().tz().format('YYYY-MM-DD');
  const activeJobs = list.rows.filter(job => job.start_date <= today && today <= job.end_date);

  const updateRtReport = activeJobs.length > 0 && documentVisible;
  useEffect(() => loopGetRtReport(updateRtReport, setReport, 60_000), [updateRtReport]);

  // set helper data for column value display
  columnHelper.setData(misc, activeJobs, report);
  const showColumns = isAdmin ?
    ['id', 'name', 'type', 'schedule', 'dayparts', 'url', 'traffic', 'plan', 'imps', 'clicks', 'ctr', 'landing', 'progress']
  :
    ['id', 'name', 'type', 'schedule', 'dayparts', 'traffic', 'plan', 'imps', 'clicks', 'ctr', 'landing', 'progress'];

  return (
    <Page title="Job List">
      <Toolbar sx={{mt: -1}}>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <ToggleButtonGroup size="small" value={filter.status} exclusive onChange={handleStatus}>
            <ToggleButton value="active" sx={{padding: '5px'}}>Active</ToggleButton>
            <ToggleButton value="running" sx={{padding: '5px'}}>Running</ToggleButton>
            <ToggleButton value="all" sx={{padding: '5px'}}>All</ToggleButton>
          </ToggleButtonGroup>
          {isAdmin && (
          <Select
            name="org"
            value={filter.org}
            size="small"
            onChange={handleFilter}
            displayEmpty
            sx={{ ml: 1, '& .MuiSelect-select': {py: '6px'} }}
          >
            <MenuItem value={''}>All Orgs</MenuItem>
            {Object.entries(misc['org']).map(([val, display]) => (
            <MenuItem value={val} key={val}>{display}</MenuItem>
            ))}
          </Select>
          )}
        </Typography>
        <Input
          sx={{p: '2px', mr: 1, width: 160}}
          name="search"
          value={search}
          size="small"
          onChange={handleFilter}
          inputProps={{ style: {marginLeft: '-5px'} }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Button color="inherit" onClick={openOverview}>Overview</Button>
        <Button color="inherit" onClick={reloadJobs}>Reload</Button>
        <IconButton color="inherit" id="moreacts" onClick={handleOpenMoreOps}>
          <MoreVertIcon />
        </IconButton>
      </Toolbar>
      <Paper sx={{p: 1, pb: 0, m: 2, mt: -1}}>
        <TableContainer sx={{overflowX: "initial"}}>
          <Table stickyHeader sx={{
            minWidth: 1050,
            '& th,td': {
              padding: 1,
              textAlign: 'center',
              whiteSpace: 'pre-line',
            }
          }}>
            <TableHead>
              <TableRow>
                {filter.status !== 'running' && (<TableCell></TableCell>)}
                {showColumns.map(col => (<TableCell key={col}>{columns[col].name}</TableCell>))}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.rows.map(row => (
                <TableRow hover key={row.id}>
                  {filter.status !== 'running' && (
                  <TableCell>
                    <Checkbox value={row.id} size="small" sx={{p:'3px'}} checked={selected.includes(row.id)} onChange={handleSelect} />
                  </TableCell>
                  )}
                  {showColumns.map(col => (<TableCell key={col}>{columns[col].value(row)}</TableCell>))}
                  <TableCell sx={{ whiteSpace: 'nowrap', '& .MuiButtonBase-root': {p: '3px'} }}>
                    <IconButton onClick={() => confirmSuspend(!row.suspend, row.id)} size="small">
                      {row.suspend ? (<PlayIcon fontSize="inherit" />) : (<PauseIcon fontSize="inherit" />)}
                    </IconButton>
                    {isWarren && [
                    <IconButton component={NavLink} to={`/jobs/${row.id}/edit`} size="small" key="edit">
                      <EditIcon fontSize="inherit" />
                    </IconButton>,
                    <IconButton component={NavLink} to={`/jobs/new?copy=${row.id}`} size="small" key="copy">
                      <CopyIcon fontSize="inherit" />
                    </IconButton>
                    ]}
                    <IconButton onClick={() => showJobState(row.id)} size="small">
                      <CalendarIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton id={'moreops-'+row.id} onClick={handleOpenMoreOps} size="small">
                      <MoreVertIcon fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={list.total}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={pagination[0]}
          rowsPerPage={pagination[1]}
          rowsPerPageOptions={[50, 100, 200]}
        />
      </Paper>
      <Menu
        id="moreacts-menu"
        anchorEl={moreOpsEl}
        open={moreopsElId === 'moreacts'}
        onClose={handleCloseMoreOps}
        onClick={handleCloseMoreOps}
      >
        {isAdmin && [
        <MenuItem component={NavLink} to={`/jobs/new`} key="add">Add Job</MenuItem>,
        <MenuItem component={NavLink} to={`/jobs/batchadd`} key="batch">Batch Add Jobs</MenuItem>,
        <MenuItem disabled={selected.length===0} onClick={batchSetPageAction} key="pageact">Set Page Action</MenuItem>
        ]}
        <MenuItem disabled={selected.length===0} onClick={exportSelectedJobs}>Export Selected Jobs</MenuItem>
        <MenuItem disabled={selected.length===0} onClick={() => confirmSuspend(true, 0)}>Pause Selected Jobs</MenuItem>
        <MenuItem disabled={selected.length===0} onClick={() => confirmSuspend(false, 0)}>Resume Selected Jobs</MenuItem>
        <MenuItem component={NavLink} to={`/reports?dim=jobdaily`} target="_blank">View History Report</MenuItem>
        <MenuItem onClick={selectExportDays}>Export All Report</MenuItem>
        <MenuItem component={NavLink} to={`/jobs/changelist`} target="_blank">View Jobs Changelist</MenuItem>
      </Menu>
      <Menu
        id="moreops-menu"
        anchorEl={moreOpsEl}
        open={Boolean(moreopsJob)}
        onClose={handleCloseMoreOps}
        onClick={handleCloseMoreOps}
      >
        {isAdmin && [
        <MenuItem component={NavLink} to={`/jobs/${moreopsJob?.id}/edit`} key="edit">
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>,
        <MenuItem component={NavLink} to={`/jobs/new?copy=${moreopsJob?.id}`} key="copy">
          <ListItemIcon>
            <CopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
        </MenuItem>,
        ]}
        <MenuItem onClick={() => clickMenu('report')}>
          <ListItemIcon>
            <ReportIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Daily Report</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => clickMenu('tracks')}>
          <ListItemIcon>
            <TrackIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Tracks</ListItemText>
        </MenuItem>
        {moreopsJob?.prepared > 0 && (
        <MenuItem onClick={() => clickMenu('device')}>
          <ListItemIcon>
            <DeviceIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Devices</ListItemText>
        </MenuItem>
        )}
        <MenuItem onClick={() => clickMenu('hourly-report')}>
          <ListItemIcon>
            <HourlyReportIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Hourly Report</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => clickMenu('region-report')}>
          <ListItemIcon>
            <LocationReportIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Report by Region</ListItemText>
        </MenuItem>
      </Menu>
      <Overview open={modalType === 'overview'} onClose={closeModal} />
      <JobState state={modalType === 'state' ? modalData : null} onClose={closeModal} />
      <JobReport job={modalType === 'report' ? modalData : null} onClose={closeModal} />
      <DeviceCheck job={modalType === 'device' ? modalData : null} onClose={closeModal} />
      <TrackList data={modalType === 'tracks' ? modalData : null} onClose={closeModal} />
      <Dialog open={!!dialogContent} onClose={closeModal}>
        <DialogContent>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button onClick={confirmDialog}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default JobList;
